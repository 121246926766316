

	usericon = [];

	usericon[1] = L.icon({
		iconUrl: '/images/user_icon_1.png',
		iconSize:     [12, 12],
		iconAnchor:   [6, 6],
		popupAnchor:  [0, -3]
	});

	usericon[2] = L.icon({
		iconUrl: '/images/user_icon_2.png',
		iconSize:     [12, 12],
		iconAnchor:   [6, 6],
		popupAnchor:  [0, -3]
	});

	usericon[3] = L.icon({
		iconUrl: '/images/user_icon_3.png',
		iconSize:     [12, 12],
		iconAnchor:   [6, 6],
		popupAnchor:  [0, -3]
	});

	usericon[4] = L.icon({
		iconUrl: '/images/user_icon_4.png',
		iconSize:     [12, 12],
		iconAnchor:   [6, 6],
		popupAnchor:  [0, -3]
	});

	usericon[5] = L.icon({
		iconUrl: '/images/user_icon_5.png',
		iconSize:     [12, 12],
		iconAnchor:   [6, 6],
		popupAnchor:  [0, -3]
	});

	usericon[6] = L.icon({
		iconUrl: '/images/user_icon_6.png',
		iconSize:     [12, 12],
		iconAnchor:   [6, 6],
		popupAnchor:  [0, -3]
	});

	usericon[7] = L.icon({
		iconUrl: '/images/user_icon_7.png',
		iconSize:     [12, 12],
		iconAnchor:   [6, 6],
		popupAnchor:  [0, -3]
	});

	usericon[8] = L.icon({
		iconUrl: '/images/user_icon_8.png',
		iconSize:     [12, 12],
		iconAnchor:   [6, 6],
		popupAnchor:  [0, -3]
	});

	usericon[9] = L.icon({
		iconUrl: '/images/user_icon_9.png',
		iconSize:     [12, 12],
		iconAnchor:   [6, 6],
		popupAnchor:  [0, -3]
	});

	usericon[10] = L.icon({
		iconUrl: '/images/user_icon_10.png',
		iconSize:     [12, 12],
		iconAnchor:   [6, 6],
		popupAnchor:  [0, -3]
	});

